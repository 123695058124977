import { MultiSelect } from "primereact/multiselect";
import React from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import styles from "./Filters.module.scss";

interface FilterProps {
  criterias: any; // The filter options from the API
  filters: any; // The currently selected filters
  setFilters: (filters: any) => void; // Function to update filters
}

const FilterLabel = ({
  label,
  tooltip,
}: {
  label: string;
  tooltip: string;
}) => (
  <>
    <OverlayTrigger
      placement="right"
      overlay={<Tooltip id={`tooltip-${label}`}>{tooltip}</Tooltip>}
      delay={{ show: 150, hide: 200 }}
    >
      <span className={styles.infoIconWrapper}>
        <span className={styles.infoIcon}>
          <i className="bi bi-info-circle"></i>
        </span>
      </span>
    </OverlayTrigger>
    <Form.Label style={{ fontSize: "0.95em" }}>{label}</Form.Label>
  </>
);

const Filters: React.FC<FilterProps> = ({ criterias, filters, setFilters }) => {
  // Helper function to create MultiSelect options
  const createOptions = (items: string[] = []) =>
    items.map((item) => ({ label: item, value: item }));

  return (
    <div className="d-flex flex-column width-full p-2">
      <Form>
        {/* Strategy/Sector Filter */}
        <Form.Group className="mb-3">
          <FilterLabel
            label="Strategy"
            tooltip="Choose the strategy of your project"
          />
          <MultiSelect
            options={createOptions(criterias?.sector)}
            value={filters?.sector || []}
            onChange={(e) => setFilters({ ...filters, sector: e.value })}
            filter
            placeholder=""
            scrollHeight="300px"
            display="chip"
            maxSelectedLabels={2}
            showClear
            style={{ width: "100%" }}
          />
        </Form.Group>

        {/* Macro Industry Filter */}
        <Form.Group className="mb-3">
          <FilterLabel
            label="Macro Industry"
            tooltip="Choose the macro industry/industries of the projects"
          />
          <MultiSelect
            options={createOptions(criterias?.macroIndustry)}
            value={filters?.macroIndustry || []}
            onChange={(e) => setFilters({ ...filters, macroIndustry: e.value })}
            filter
            placeholder=""
            scrollHeight="300px"
            display="chip"
            maxSelectedLabels={2}
            showClear
            style={{ width: "100%" }}
          />
        </Form.Group>

        {/* Sub Industry Filter */}
        <Form.Group className="mb-3">
          <FilterLabel
            label="Sub Industry"
            tooltip="Choose the sub-industry/industries of the projects"
          />
          <MultiSelect
            options={createOptions(criterias?.subIndustry)}
            value={filters?.subIndustry || []}
            onChange={(e) => setFilters({ ...filters, subIndustry: e.value })}
            filter
            placeholder=""
            scrollHeight="300px"
            display="chip"
            maxSelectedLabels={2}
            showClear
            style={{ width: "100%" }}
          />
        </Form.Group>

        {/* Project Year Filter */}
        <Form.Group className="mb-3">
          <FilterLabel
            label="Project Year"
            tooltip="Choose the year/s of the project"
          />
          <MultiSelect
            options={createOptions(criterias?.year)}
            value={filters?.year || []}
            onChange={(e) => setFilters({ ...filters, year: e.value })}
            filter
            placeholder=""
            scrollHeight="300px"
            display="chip"
            maxSelectedLabels={2}
            showClear
            style={{ width: "100%" }}
          />
        </Form.Group>

        {/* Document Type Filter */}
        <Form.Group className="mb-3">
          <FilterLabel
            label="Document Type"
            tooltip="Choose the type/s of document that you want to search"
          />
          <MultiSelect
            options={createOptions(criterias?.type)}
            value={filters?.type || []}
            onChange={(e) => setFilters({ ...filters, type: e.value })}
            filter
            placeholder=""
            scrollHeight="300px"
            display="chip"
            maxSelectedLabels={2}
            showClear
            style={{ width: "100%" }}
          />
        </Form.Group>

        {/* Project Name Filter */}
        <Form.Group className="mb-3">
          <FilterLabel
            label="Project Name"
            tooltip="Choose the name/s of your projects"
          />
          <MultiSelect
            options={createOptions(criterias?.projectName)}
            value={filters?.projectName || []}
            onChange={(e) => setFilters({ ...filters, projectName: e.value })}
            filter
            placeholder=""
            scrollHeight="300px"
            display="chip"
            maxSelectedLabels={2}
            showClear
            style={{ width: "100%" }}
          />
        </Form.Group>
      </Form>
    </div>
  );
};

export default Filters;
