import { useIsAuthenticated } from "@azure/msal-react";
import { useLocation } from "react-router-dom"; // Import useLocation
import React, { useState, useEffect } from "react"; // Import useState and useEffect
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../assets/images/logo.svg";
import { SignInButton } from "./Auth/SignInButton";
import { SignOutButton } from "./Auth/SignOutButton";
import { Link } from "react-router-dom"; // Import Link
import styles from "./NavigationBar.module.scss"; // Import the styles
import { Button } from "react-bootstrap";

export const NavigationBar = () => {
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("");

  useEffect(() => {
    // Set the active link based on the current path
    if (location.pathname.includes("/market")) {
      setActiveLink("market");
    } else if (location.pathname.includes("/mydeal")) {
      setActiveLink("mydeal");
    } else if (location.pathname.includes("/legal")) {
      setActiveLink("legal");
    } else if (location.pathname.includes("/oreima")) {
      setActiveLink("oreima");
    } else {
      setActiveLink("");
    }
  }, [location.pathname]);

  const handleLinkClick = (link: string) => {
    setActiveLink(link);
  };

  const handleEmailClick = () => {
    window.location.href = "mailto:georges@infraviacapital.com";
  };

  const handleUserGuideClick = () => {
    window.open(
      "https://infraviacapital.sharepoint.com/:f:/r/sites/prd-team-Infravia-Toolbox-And-Procedures/IT/Georges%20AI?csf=1&web=1&e=0d68A2",
      "_blank"
    );
  };

  return (
    <Navbar
      expand="lg"
      className="bg-primary sticky-top"
      style={{ zIndex: 1000 }}
    >
      <Container fluid style={{ paddingLeft: "5%", paddingRight: "5%" }}>
        <Navbar.Brand href="/">
          <img
            src={logo}
            height="40px"
            className="d-inline-block align-top"
            alt="InfraVia"
          />
        </Navbar.Brand>

        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className="navbar-dark"
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav
            className="mx-auto d-flex justify-content-center"
            style={{
              textTransform: "uppercase",
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            <Nav.Link
              as={Link}
              to="/market"
              className={`text-bg-primary me-4 ${styles.navLink} ${
                activeLink === "market" ? `${styles.active} fw-bold` : ""
              }`}
              onClick={() => setActiveLink("market")}
            >
              Market
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/mydeal"
              className={`text-bg-primary me-4 ${styles.navLink} ${
                activeLink === "mydeal" ? `${styles.active} fw-bold` : ""
              }`}
              onClick={() => setActiveLink("mydeal")}
            >
              Live Deal
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/legal"
              className={`text-bg-primary me-4 ${styles.navLink} ${
                activeLink === "legal" ? `${styles.active} fw-bold` : ""
              }`}
              onClick={() => setActiveLink("legal")}
            >
              Legal & Financing
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/oreima"
              className={`text-bg-primary me-4 ${styles.navLink} ${
                activeLink === "oreima" ? `${styles.active} fw-bold` : ""
              }`}
              onClick={() => setActiveLink("oreima")}
            >
              Oreima Deals
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
        {/* Buttons on the right */}
        <div className="d-flex align-items-center ms-auto">
          {/* User Guide Button */}
          <Button
            variant="link"
            className={`text-bg-primary me-4 nav-link`}
            onClick={handleUserGuideClick}
            style={{
              fontSize: "18px",
              // fontWeight: "bold",
              fontStyle: "italic",
              // textTransform: "uppercase",
              padding: 0,
            }}
          >
            How to use Georges
          </Button>

          {/* Write to Georges Button */}
          <Button
            variant="link"
            className={`text-bg-primary nav-link`}
            onClick={handleEmailClick}
            style={{
              fontSize: "18px",
              // fontWeight: "bold",
              fontStyle: "italic",
              // textTransform: "uppercase",
              padding: 0,
            }}
          >
            Write to Georges
          </Button>

          {/* Authenticated Button with margin */}
          <div style={{ marginLeft: "20px" }}>
            {" "}
            {/* Add margin here */}
            {isAuthenticated ? <SignOutButton /> : <SignInButton />}
          </div>
        </div>
      </Container>
    </Navbar>
  );
};
