import { PublicClientApplication } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { PrimeReactProvider } from "primereact/api";
import React from "react";
import "react-range-slider-input/dist/style.css";
import { Navigate, Route, Routes } from "react-router-dom";
import "react-widgets/styles.css";
import "./App.scss";
import ErrorBanner from "./components/ErrorBanner";
import { PageLayout } from "./components/PageLayout";
import { accessGroups, AccessiblePage } from "./config/authConfig";
import { ErrorProvider } from "./contexts/ErrorContext";
import useGroupMembership from "./hooks/useGroupMembership";
import Home from "./pages/Home/Home";
import Legal from "./pages/Legal/Legal";
import LegalOreima from "./pages/LegalOreima/LegalOreima";
import Market from "./pages/Market/Market";
import MyDeal from "./pages/MyDeal/MyDeal";

const NoAccess = () => (
  <div className="d-flex justify-content-center align-items-center h-100">
    <div className="text-center">
      <h3>Access Denied</h3>
      <p>You don't have permission to access this page.</p>
      <p>
        If you believe you should have access, please click on write to Georges.
      </p>
    </div>
  </div>
);

const ProtectedRoute = ({
  element,
  page,
}: {
  element: JSX.Element;
  page: AccessiblePage;
}) => {
  const userGroups = useGroupMembership();
  if (!userGroups) return <div>Loading...</div>;

  const hasAccess = Object.values(accessGroups).some(
    (group) =>
      group.groupIds.some((groupId) => userGroups.includes(groupId)) &&
      group.pages.includes(page)
  );

  return hasAccess ? element : <NoAccess />;
};

const Pages = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route
      path="/market"
      element={<ProtectedRoute element={<Market />} page="market" />}
    />
    <Route
      path="/mydeal"
      element={<ProtectedRoute element={<MyDeal />} page="mydeal" />}
    />
    <Route
      path="/legal"
      element={<ProtectedRoute element={<Legal />} page="legal" />}
    />
    <Route
      path="/oreima"
      element={<ProtectedRoute element={<LegalOreima />} page="oreima" />}
    />
    <Route path="*" element={<Navigate to="/" />} />
  </Routes>
);

interface AppProps {
  instance: PublicClientApplication;
}

const App: React.FC<AppProps> = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <PrimeReactProvider>
        <ErrorProvider>
          <PageLayout>
            <ErrorBanner />
            <AuthenticatedTemplate>
              <Pages />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <Home />
            </UnauthenticatedTemplate>
          </PageLayout>
        </ErrorProvider>
      </PrimeReactProvider>
    </MsalProvider>
  );
};

export default App;
