import { AccountInfo, IdTokenClaims } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";

const useGroupMembership = (): string[] | null => {
  const { instance } = useMsal();

  // Get the active account
  const account: AccountInfo | null = instance.getActiveAccount();

  if (!account) {
    return null; // Return null if no active account is found
  }

  // Extract and type the ID token claims
  const idTokenClaims = account.idTokenClaims as IdTokenClaims & {
    groups?: string[];
  };

  // Return the groups claim or an empty array if not present
  return idTokenClaims.groups || [];
};

export default useGroupMembership;
