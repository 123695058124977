import React, { useMemo, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Panel, PanelGroup } from "react-resizable-panels";
import Chat from "./Chatmarket";
import { HandlePanel } from "../../../components/HandlePanel/HandlePanel";
import { ChatProvider } from "../../../contexts/ChatContext";
import useFiltersMarket from "../../../hooks/useFiltersMarket";
import Menu from "./Menu/Menu";
import SourceViewer from "./SourceViewer";
import content from "../../../data/textboxContent.json";

function MarketContent() {
  const filtersEndpoint = `${process.env.REACT_APP_API_URI}/market/filters`;
  const {
    filters: criterias,
    selectedFilters,
    setSelectedFilters,
  } = useFiltersMarket(filtersEndpoint);

  const [selectedText, setSelectedText] = useState("");
  const [selectedMode, setSelectedMode] = useState("UNDEFINED");

  // Memoize the endpoint URLs
  const endpoints = useMemo(
    () => ({
      threadsEndpoint: `${process.env.REACT_APP_API_URI}/session_manager/thread_ids`,
      chatEndpoint: `${process.env.REACT_APP_API_URI}/market/chat`,
      messagesEndpoint: `${process.env.REACT_APP_API_URI}/session_manager/thread_history`,
      feedbackEndpoint: `${process.env.REACT_APP_API_URI}/session_manager/feedback_update`,
    }),
    []
  );

  const handleSquareClick = (
    text: string,
    mode: string,
    newFilters: object
  ) => {
    setSelectedText(text);
    setSelectedMode(mode);

    // Update filters with the new values
    if (newFilters) {
      setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        ...newFilters,
      }));
    }
  };

  const pageContent = useMemo(() => content["market"], []);

  const groupedContent = useMemo(() => {
    return pageContent.containers.reduce((acc, container) => {
      acc[container.key] = {
        title: container.title,
        tooltip: container.tooltip,
        items: container.squares,
      };
      return acc;
    }, {});
  }, [pageContent]);

  const modeOptions = useMemo(() => {
    return pageContent.containers.map((container) => ({
      value: container.modeValue,
      icon: container.icon,
      label: container.label,
      tooltip: container.tooltip,
    }));
  }, [pageContent]);

  return (
    <ChatProvider {...endpoints}>
      <Row id="container" className="h-100 mh-100 overflow-hidden p-0">
        <Col
          md={2}
          className="bg-light border-end height-full overflow-hidden p-0 mh-100 h-100"
          style={{ width: "fit-content" }}
          id="filters"
        >
          <Menu
            criterias={criterias}
            filters={selectedFilters}
            setFilters={setSelectedFilters}
          />
        </Col>

        <Col className="d-flex flex-column mh-100 h-100 overflow-hidden">
          <Row className="flex-fill overflow-hidden">
            <PanelGroup
              autoSaveId="market"
              direction="horizontal"
              className="g-0"
            >
              <Panel defaultSizePercentage={70} minSizePercentage={30}>
                <Chat
                  filters={selectedFilters}
                  page="market"
                  selectedText={selectedText}
                  selectedMode={selectedMode}
                  setSelectedText={setSelectedText}
                  setSelectedMode={setSelectedMode}
                  groupedContent={groupedContent}
                  modeOptions={modeOptions}
                  onSquareClick={handleSquareClick}
                />
              </Panel>
              <HandlePanel />
              <Panel defaultSizePercentage={30} minSizePercentage={20}>
                <Col className="d-flex flex-column h-100">
                  <SourceViewer />
                </Col>
              </Panel>
            </PanelGroup>
          </Row>
        </Col>
      </Row>
    </ChatProvider>
  );
}

export default MarketContent;
